<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                
                <div class="row g-0" v-show="!preloader">
                    <div class="col-12 col-xxl-12 px-0 bg-soft">
                        <div class="mb-5">
                            <div class="d-flex justify-content-between">
                                <h2 class="text-black fw-bolder mb-2">Логи игр</h2>
                            </div>
                        </div>
                        <div class="row gx-0 gx-sm-5 gy-8" style="margin-bottom: -40px">
                            <div class="col-12 col-xl-3 col-xxl-4 pe-xl-0">
                                <div class="mb-2">
                                    <div class="row gx-0 gx-sm-7 gx-md-9">
                                        <div class="col-md-12">

                                            <div class="row">
                                              <div class="col-md-12">
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Период выборки</h6>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-2">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="fa-regular fa-clock mr-2"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Дата с</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ $moment(search.date.startDate).format('YYYY-MM-DD H:mm') }}</h6>
                                                  </div>
                                                </div>
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="fa-regular fa-clock mr-2"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Дата по</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ $moment(search.date.endDate).format('YYYY-MM-DD H:mm') }}</h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="row mt-4">
                                              <div class="col-md-12">
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Данные за период</h6>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="bullet-item me-2 bg-warning"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Количество игр</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ chart_data.total.games }}</h6>
                                                  </div>
                                                </div>
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="bullet-item me-2 bg-primary"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Ставки</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ parseFloat(chart_data.total.bets).toFixed(2) }}</h6>
                                                  </div>
                                                </div>
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="bullet-item me-2 bg-info"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Выигрыш</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ parseFloat(chart_data.total.win).toFixed(2) }}</h6>
                                                  </div>
                                                </div>
                                                <div class="row justify-content-center justify-content-sm-between g-5 g-sm-0 mb-3">
                                                  <div class="col">
                                                    <div class="d-flex align-items-center">
                                                      <div class="bullet-item me-2 bg-danger"></div>
                                                      <h6 class="text-900 fw-semi-bold flex-1 mb-0">Проигрыш</h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-auto">
                                                    <h6 class="text-900 fw-semi-bold mb-0">{{ (parseFloat(chart_data.total.bets) - parseFloat(chart_data.total.win)).toFixed(2) }}</h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-xl-9 col-xxl-8">
                                <div class="echart-completed-task-chart">
                                    <v-chart 
                                        class="chart" 
                                        :manual-update="true" 
                                        :autoresize="true" 
                                        ref="chart"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row align-items-center justify-content-between g-3 mb-3" v-show="!preloader">
                    
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container" id="merchant_block">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.merchant"
                                :options="utils.merchants" 
                                placeholder="Мерчант"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchUsers"
                                v-model="search.user"
                                :options="utils.users" 
                                placeholder="Пользователь"
                                label="email">
                                <template v-slot:option="option">
                                    <span>{{ option.email }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchGames"
                                v-model="search.game"
                                :options="utils.games" 
                                placeholder="Игра"
                                label="name">
                                <template v-slot:option="option">
                                    <img style="width: 50px;margin-right: 20px;height: 100%; height: 35px;" :src="`${$config.ApplicationStaticURL}/icos/${option.image}`"/>
                                    <span>{{ option.name }}</span>
                                    <template v-if="option.is_mobile">
                                        <span class="badge badge-casino fs--3 badge-casino-warning" style="float: right;margin-top: 10px;">Mobile</span>
                                    </template>
                                    <template v-else>
                                        <span class="badge badge-casino fs--3 badge-casino-success" style="float: right;margin-top: 10px;">Desktop</span>
                                    </template>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.provider"
                                :options="utils.providers" 
                                placeholder="Провайдер"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.category"
                                :options="utils.categories" 
                                placeholder="Категория"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.platform"
                                :options="utils.platforms" 
                                placeholder="Платформа"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small fs--1">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td><router-link :to="{name: 'UsersItem', params: {id: row.user_id}}">{{ row.email }}</router-link></td>
                                        <td><router-link :to="{name: 'GamesItem', params: {id: row.game_id}}">{{ row.game_name }}</router-link></td>
                                        <td>{{ row.action }}</td>
                                        <td>{{ row.type }}</td>
                                        <td>{{ parseFloat(row.bet).toFixed(2) }}</td>
                                        <td>{{ parseFloat(row.win).toFixed(2) }}</td>
                                        <td>{{ parseFloat(row.bet) - parseFloat(row.win) > 0 ? parseFloat(row.bet - row.win).toFixed(2) : 0 }}</td>
                                        <td>{{ parseFloat(row.user_balance).toFixed(2) }}</td>
                                        <td>{{ row.session_id }}</td>
                                        <td>{{ row.transaction_id }}</td>
                                        <td>{{ row.round_id }}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>

export default {
    data () {
        return {
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            chart_data: {
                items: [],
                total: {
                    //games: 0,
                    bets: 0,
                    win: 0
                }
            },
            search: {
                search_change: null,
                user: null,
                merchant: {
                    name: 'Rubbles',
                    currency: 'RUB',
                    id: 1
                },
                game: null,
                provider: null,
                category: null,
                platform: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            columns: [
                {label: 'ID', field: 'id', sortable: true},
                {label: 'Email'},
                {label: 'Игра'},
                {label: 'Действие'},
                {label: 'Тип'},
                {label: 'Ставка'},
                {label: 'Выигрыш'},
                {label: 'Проигрыш'},
                {label: 'Баланс'},
                {label: 'Session ID'},
                {label: 'Transaction ID'},
                {label: 'Round ID'},
                {label: 'Дата'},
            ],
            utils: {
                users: [],
                games: [],
                merchants: [],
                providers: [],
                categories: [],
                platforms: [
                    {
                        name: 'Desktop',
                        key: 'desktop'
                    },
                    {
                        name: 'Mobile',
                        key: 'mobile'
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            },
            option: {
                grid: {
                    width: '98%',
                    right: '1%',
                    left: '1%',
                    containLabel: true
                },
                title: {
                    text: 'График активности',
                    subtext: 'Количество игр, ставки, выигрыш и проигрыш',
                    textStyle: {
                        color: '#141824'
                    },
                    subtextStyle: {
                        color: 'rgb(110, 120, 145)'
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    padding: 10, 
                    backgroundColor: '#eff2f6', 
                    borderColor: '#cbd0dd', 
                    textStyle: { 
                        color: '#141824'
                    },
                    borderWidth: 1,
                    transitionDuration: 0,
                    axisPointer: {
                        type: 'none'
                    }
                },
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                            name: 'chart_' + Date.parse(new Date())/1000,
                            title: 'Сохранить изображение', 
                            type: 'png',
                            iconStyle: {
                                borderColor: 'rgb(110, 120, 145)'
                            },
                            emphasis: {
                                iconStyle: {
                                    borderColor: '#3874ff'
                                }
                            }
                        },
                        magicType: {
                            title: {
                                line: 'Линейный график', 
                                bar: 'Гистограмма', 
                                stack: 'Стек'
                            },
                            iconStyle: {
                                borderColor: 'rgb(110, 120, 145)'
                            },
                            emphasis: {
                                iconStyle: {
                                    borderColor: '#3874ff'
                                }
                            },
                            type: ['line', 'bar', 'stack']
                        }
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#e0e6f1'
                            },
                            show: true
                        },
                        boundaryGap: false,
                        data: []
                    },
                    {
                        type: 'category',
                        position: 'bottom',
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#e0e6f1'
                            }
                        },
                        axisLabel: {
                            formatter: (e) => {
                                var index = this.option.xAxis[1].data.findIndex(row => row == e)
                                if(index == 0) {
                                    return `{first|${e}}`
                                } else if(index == this.option.xAxis[1].data.length - 1) {
                                    return `{last|${e}}`
                                }
                                return e
                            },
                            rich: {
                                first: {
                                    padding: [0,0,0,55]
                                },
                                last: {
                                    padding: [0,55,0,0]
                                }
                            },
                            color: 'rgb(110, 120, 145)'
                        },
                        splitLine: {
                            show: false
                        },
                        boundaryGap: false,
                        data: []
                    }
                ],
                yAxis: {
                    position: 'right', 
                    axisPointer: {
                        type: 'none'
                    }, 
                    axisTick: 'none', 
                    splitLine: {
                        show: false
                    }, 
                    axisLine: {
                        show: false
                    }, 
                    axisLabel: {
                        show: false
                    }
                },
                series: [
                    {
                        lineStyle: {
                            color: 'rgb(0, 151, 235)'
                        },
                        itemStyle: {
                            normal: {
                                color: 'rgb(0, 151, 235)'
                            }
                        },
                        showSymbol: false, 
                        symbol: 'circle',
                        name: 'Выигрыш',
                        type: 'line',
                        data: []
                    },
                    {
                        type: 'line',
                        showSymbol: false, 
                        symbol: 'circle',
                        lineStyle: {
                            type: 'dashed', 
                            width: 1,
                            color: '#ed2000'
                        },
                        itemStyle: {
                            normal: {
                                color: '#ed2000'
                            }
                        },
                        name: 'Проигрыш',
                        data: []
                    },
                    {
                        lineStyle: {
                            color: '#e5780b'
                        },
                        itemStyle: {
                            normal: {
                                color: '#e5780b'
                            }
                        },
                        showSymbol: false, 
                        symbol: 'circle',
                        name: 'Кол. игр',
                        type: 'line',
                        data: []
                    },
                    {
                        lineStyle: {
                            color: '#3874ff'
                        },
                        itemStyle: {
                            normal: {
                                color: '#3874ff'
                            }
                        },
                        showSymbol: false, 
                        symbol: 'circle',
                        name: 'Ставки',
                        type: 'line',
                        data: []
                    }
                ]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/games/logs/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user: this.search.user ? this.search.user.id : null,
                    merchant: this.search.merchant ? this.search.merchant.id : null,
                    game: this.search.game ? this.search.game.id : null,
                    provider: this.search.provider ? this.search.provider.id : null,
                    category: this.search.category ? this.search.category.id : null,
                    platform: this.search.platform ? this.search.platform.key : null,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        end: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.chart_data.items = items.data.chart

                this.chart_data.total.games = items.data.count
                //this.chart_data.total.games = this.sumChartFields('games') * 2
                this.chart_data.total.bets = this.sumChartFields('bets')
                this.chart_data.total.win = this.sumChartFields('win')


                //chart_data

                this.option.series[0].data = []
                this.option.series[1].data = []
                this.option.series[2].data = []
                this.option.series[3].data = []

                this.option.xAxis[0].data = []
                this.option.xAxis[1].data = []

                if(this.chart_data.items.length) {
                    for (let item of this.chart_data.items) {
                        this.option.series[0].data.push(parseFloat(item.win).toFixed(2))
                        this.option.series[1].data.push((parseFloat(item.bets) - parseFloat(item.win)).toFixed(2))
                        this.option.series[2].data.push(parseFloat(item.games).toFixed(2))
                        this.option.series[3].data.push(parseFloat(item.bets).toFixed(2))

                        this.option.xAxis[0].data.push(this.$moment(item.date).format('DD MMMM'))
                    }

                    this.option.xAxis[1].data.push(this.option.xAxis[0].data[0])
                    this.option.xAxis[1].data.push(this.option.xAxis[0].data[Math.floor((this.option.xAxis[0].data.length - 1) / 2)])
                    this.option.xAxis[1].data.push(this.option.xAxis[0].data[this.option.xAxis[0].data.length - 1])
                }


                this.$refs.chart.setOption(this.option)

                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getProviders () {
            this.$axios.post('/games/providers/items', JSON.stringify({
                search: {
                    name: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.providers.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getCategories () {
            this.$axios.post('/games/categories/items', JSON.stringify({
                search: {
                    name: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.categories.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        searchGames (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/games/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.games = []
                            for (let game of response.data) {
                                this.utils.games.push({
                                    id: game.id,
                                    name: game.name,
                                    image: game.image,
                                    is_mobile: game.is_mobile
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        sumChartFields (key) {
            return this.chart_data.items.reduce((accumulator, object) => {
                return accumulator + parseFloat(object[key])
            }, 0)
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        }
    },
    computed: {
        changeTheme() {
            return this.$store.state.theme
        }
    },
    watch: {
        changeTheme (newTheme, oldTheme) {
            if(newTheme == 'dark') {
                this.option.title.textStyle.color = '#e3e6ed'
                this.option.xAxis[0].splitLine.lineStyle.color = '#313749'
                this.option.xAxis[1].axisLine.lineStyle.color = '#313749'
                this.option.tooltip.backgroundColor = 'rgb(34, 40, 52)'
                this.option.tooltip.borderColor = 'rgb(55, 62, 83)'
                this.option.tooltip.textStyle.color = 'rgb(239, 242, 246)'
            } else {
                this.option.title.textStyle.color = '#141824'
                this.option.xAxis[0].splitLine.lineStyle.color = '#e0e6f1'
                this.option.xAxis[1].axisLine.lineStyle.color = '#e0e6f1'
                this.option.tooltip.backgroundColor = '#eff2f6'
                this.option.tooltip.borderColor = '#cbd0dd'
                this.option.tooltip.textStyle.color = '#141824'
            }
            this.$refs.chart.setOption(this.option)
        }
    },
    beforeMount() {
        this.getMerchants()
        this.getProviders()
        this.getCategories()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep #merchant_block .vs__clear {
        display: none;
    }
    ::v-deep .chart {
        height: 330px;
    }
</style>
