import { render, staticRenderFns } from "./Items.vue?vue&type=template&id=330c0484&"
import script from "./Items.vue?vue&type=script&lang=js&"
export * from "./Items.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports